import React, { FC, MouseEventHandler } from "react";
import { Empty, Button, Typography } from "antd";

export interface LoginRequiredProps {
  action: MouseEventHandler<HTMLAnchorElement>;
}

export const LoginRequired: FC<LoginRequiredProps> = ({ action }) => {
  return (
    <div className="xvia-login-required-area">
      <Empty
        imageStyle={{
          height: 120,
        }}
        description={
          <div>
            <Typography.Title level={2}>Login necessário</Typography.Title>
            <span>Você precisa estar logado para acessar esta página</span>
          </div>
        }
      >
        <Button type="primary" onClick={action}>
          Entrar ou criar uma conta
        </Button>
      </Empty>
    </div>
  );
};

import React, { FC, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  NotAuthorized,
  NotAuthorizedProps,
} from "../components/EmptyState/NotAuthorized";

export const NotAuthorizedContainer: FC = () => {
  const { push } = useHistory();

  const action = useCallback(() => {
    if (window && window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "CLOSE_PAGE",
          payload: {},
        })
      );
    } else {
      push("/");
    }
  }, [push]);

  const props: NotAuthorizedProps = {
    action,
  };

  return <NotAuthorized {...props} />;
};

import React, { FC, useCallback } from "react";
// import { useKeycloak } from "@react-keycloak/web";
import {
  LoginRequired,
  LoginRequiredProps,
} from "../components/EmptyState/LoginRequired";
import { useKeycloak } from "@react-keycloak/ssr";
import { KeycloakInstance } from "keycloak-js";

export const LoginRequiredContainer: FC = () => {
  const { keycloak } = useKeycloak<KeycloakInstance>();

  const action = useCallback(() => {
    keycloak?.login();
  }, [keycloak]);

  const props: LoginRequiredProps = {
    action,
  };

  return <LoginRequired {...props} />;
};

export function removeUrlParam(url: string, parameter: string): string {
  //prefer to use l.search if you have a location/link object
  let kvp = "";
  let hash = "";
  const hashParts = url.split("#");
  if (hashParts.length >= 2) {
    hash = hashParts[1];
  }
  const urlparts = hashParts[0].split("?");
  if (urlparts.length >= 2) {
    const prefix = encodeURIComponent(parameter) + "=";
    const pars = urlparts[1].split(/[&;]/g);

    //reverse iteration as may be destructive
    for (let i = pars.length; i-- > 0; ) {
      //idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    kvp = pars.length > 0 ? "?" + pars.join("&") : "";
  }
  const urlWithParams = [urlparts[0], kvp].filter((i) => !!i.length).join("");
  return [urlWithParams, hash].filter((i) => !!i.length).join("#");
}

export function getOriginFromUrl(url?: string) {
  if (!url || !isValidUrl(url)) {
    return "";
  }

  const pathArray = url.split("/");
  const protocol = pathArray[0];
  const host = pathArray[2];
  return protocol + "//" + host;
}

function isValidUrl(text: string): boolean {
  // eslint-disable-next-line no-useless-escape
  return /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/.test(text);
}

export function createSlug(text: string) {
  return text
    .toString()
    .normalize("NFD") // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, "") // remove all previously split accents
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, "") // remove all chars not letters, numbers and spaces (to be replaced)
    .trim()
    .replace(/\s+/g, "-");
}

import { FC } from "react";
import { Modal } from "antd";
// import dayjs from "dayjs";
// import { ExternalLink } from "components/Link/ExternalLink";
import Button from "@mui/material/Button";

export interface Notification {
  body: string;
  createdAt: string;
  data: any;
  messageId: string;
  read: boolean;
  title: string;
  userId: string;
}

export interface ModalNativeLinkProps {
  showModal: boolean;
  closeModal: () => void;
}

export const ModalNativeLink: FC<ModalNativeLinkProps> = ({
  showModal,
  closeModal,
}) => {
  return (
    <Modal
      className="modal-notification"
      title=" "
      centered
      open={showModal}
      onCancel={() => closeModal()}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      footer={null}
      width={732}
    >
      <p className="notification-title">Acesso Inválido</p>
      <p className="notification-description">
        O link só pode ser acessado via aplicativo MT Cidadão.
      </p>
      <Button
        variant="contained"
        onClick={() => (window.location.href = "https://mtcidadao.mt.gov.br/")}
        fullWidth
      >
        Ver Website
      </Button>
    </Modal>
  );
};

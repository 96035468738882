import { FC, PropsWithChildren } from "react";
import {
  createTheme,
  ThemeProvider as MaterialThemeProvider,
} from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0048B6",
    },
  },
});

export const ThemeProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <MaterialThemeProvider theme={theme}>{children}</MaterialThemeProvider>
  );
};

import { useEffect } from "react";
import ReactGA from "react-ga4";

export interface PageTrackingGAOptions {
  pathname: string;
  search: string;
}

export interface EventTrackingGAOptions {
  category: string;
  action: string;
  label?: string;
  value?: number;
  nonInteraction?: boolean;
  transport?: "beacon" | "image" | "xhr";
}

/**
 * @param location PageTrackingGAOptions
 */
export function usePageTrackingGA(location: PageTrackingGAOptions): void {
  useEffect(() => {
    if (!ReactGA.isInitialized) return;

    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location.pathname, location.search]);
}

/**
 * @param options EventTrackingGAOptions
 */
export function useEventTrackingGA(options: EventTrackingGAOptions): void {
  useEffect(() => {
    if (!ReactGA.isInitialized) return;

    ReactGA.event({
      nonInteraction: true,
      transport: "xhr",
      ...options,
    });
  }, [options]);
}

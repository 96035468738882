import { Header } from "../components/Header";
import { usePortalProvider } from "../providers/portal-provider";

export const HeaderContainer = () => {
  const { noHeader } = usePortalProvider();

  if (noHeader) return null;

  return (
    <Header.Root>
      <Header.Logo src="/assets/img/logo/logo-2.png" />

      <Header.Elements />
    </Header.Root>
  );
};

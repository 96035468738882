import { Topbar } from "components/Topbar";
import { FC, PropsWithChildren } from "react";

export const HeaderRoot: FC<PropsWithChildren<unknown>> = ({ children }) => (
  <header className="xvia-header-area">
    <Topbar.Element />

    <div className="xvia-header-spacing">{children}</div>
  </header>
);

export const ProfileLocalStorage = "selectedProfile";

export type ProfileType = "default" | "company" | "server";

export type ProfileColors = {
  name?: string;
  background?: string;
  primary?: string;
};

export const ColorCompany: ProfileColors = {
  name: "Empresarial",
  background: "#335D52",
  primary: "#fff",
};

export const ColorServer: ProfileColors = {
  name: "Servidor",
  background: "#693384",
  primary: "#fff",
};

export const ColorDefault: ProfileColors = {
  name: "Cidadão",
  background: "#0048B6",
  primary: "#fff",
};

export function getProfileColorsFromContext(
  profile: ProfileType
): ProfileColors {
  switch (profile) {
    case "company":
      return {
        name: "Empresarial",
        background: "#335D52",
        primary: "#fff",
      };
    case "server":
      return {
        name: "Servidor",
        background: "#693384",
        primary: "#fff",
      };
    default:
      return {
        name: "Cidadão",
        background: "#693384",
        primary: "#fff",
      };
  }
}

export const FontFamily = {
  // Primary Colors
  light: "Roboto",
  regular: "Roboto",
  primary: "Roboto",
};

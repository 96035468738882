import { TopbarRoot } from "./TopbarRoot";
import { TopbarLink } from "./TopbarLink";
import { TopbarMobile } from "./TopbarMobile";
import { TopbarDesktop } from "./TopbarDesktop";
import { TopbarElement } from "./TopbarElement";

export const Topbar = {
  Root: TopbarRoot,
  Link: TopbarLink,
  Desktop: TopbarDesktop,
  Mobile: TopbarMobile,
  Element: TopbarElement,
};

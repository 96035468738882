import React, { FC, useMemo } from "react";
import { Spin } from "antd";
import { LoginRequiredContainer } from "../../containers/LoginRequiredContainer";
import { NotFoundContainer } from "../../containers/NotFoundContainer";
import { AppResponse } from "shared-types";
import { AppBundleLaucher } from "./AppBundleLaucher";
import { AppIframeLauncher } from "./AppIframeLaucher";
import { RequestStatus } from "../../util/Service";
import { NotAuthorizedContainer } from "../../containers/NotAuthorizedContainer";
import { GenericErrorContainer } from "../../containers/GenericErrorContainer";
export interface AppLoaderProps {
  app?: AppResponse | null;
  loading: boolean;
  authenticated: boolean;
  ssr?: boolean;
  requestStatus?: RequestStatus;
}

export const AppLoader: FC<AppLoaderProps> = ({
  app,
  loading,
  authenticated,
  ssr,
  requestStatus,
}) => {
  const isUnauthorized = useMemo(() => {
    return requestStatus === RequestStatus.UNAUTHORIZED;
  }, [requestStatus]);

  if (loading) {
    return <Spin />;
  }

  if (isUnauthorized && authenticated) {
    return <NotAuthorizedContainer />;
  }

  if (requestStatus === RequestStatus.NOT_FOUND) {
    return <NotFoundContainer />;
  }

  if (requestStatus === RequestStatus.INTERNAL_ERROR) {
    return <GenericErrorContainer />;
  }

  if (isUnauthorized && !authenticated) {
    return <LoginRequiredContainer />;
  }

  if (!app || (!app && ssr)) {
    return null;
  }

  if (app.integrationType === "iframe") {
    return <AppIframeLauncher app={app} />;
  }

  if (app.integrationType === "bundle") {
    return <AppBundleLaucher app={app} />;
  }

  return null;
};

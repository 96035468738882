import { Header } from "components/Header";
import LoginSharpIcon from "@mui/icons-material/LoginSharp";
import { usePortalAuth } from "@/providers/keycloak-provider";
import { useKeycloak } from "@react-keycloak/ssr";
import { KeycloakInstance } from "keycloak-js";
import { usePortalProvider } from "@/providers/portal-provider";

export const HeaderElementsUnauthorized = () => {
  const { keycloak, initialized } = useKeycloak<KeycloakInstance>();
  const { login } = usePortalAuth();
  const { ssr, profile } = usePortalProvider();

  const onLogin = () => {
    if (ssr) {
      login();
      return;
    }
    keycloak?.login();
  };

  if (profile) {
    return null;
  }

  return (
    <Header.Button
      color="primary"
      startIcon={<LoginSharpIcon />}
      text="Entrar"
      onClick={onLogin}
      disabled={!initialized}
    />
  );
};

import Button, { ButtonProps } from "@mui/material/Button";
import { FC, PropsWithChildren } from "react";

type HeaderButtonProps = {
  text: string;
} & ButtonProps;

export const HeaderButton: FC<PropsWithChildren<HeaderButtonProps>> = ({
  children,
  size = "small",
  variant = "contained",
  text,
  ...rest
}) => (
  <Button size={size} variant={variant} {...rest}>
    {text}
  </Button>
);

import { clsx } from "clsx";

type TopbarRootProps = React.HTMLAttributes<HTMLDivElement> & {
  isMobile?: boolean;
};

export const TopbarRoot: React.FC<React.PropsWithChildren<TopbarRootProps>> = ({
  children,
  isMobile,
  ...rest
}) => {
  const classNames = clsx(["xvia-header-topbar", "xvia-header-spacing"], {
    "xvia-header-topbar__mobile": isMobile,
    "xvia-header-topbar__desktop": !isMobile,
  });

  return (
    <div {...rest} className={classNames}>
      {children}
    </div>
  );
};

import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  getProfileColorsFromContext,
  ProfileColors,
  ProfileLocalStorage,
  ProfileType,
} from "@/util/profile";
import { usePortalProvider } from "./portal-provider";

type ProfileContextType = {
  profile: ProfileColors;
  availableProfiles: ProfileType[];
  setProfile: (newProfile: ProfileType) => void;
  getProfile: () => void;
};

const ProfileContext = createContext<ProfileContextType>({
  profile: getProfileColorsFromContext("default"),
  availableProfiles: [],
  setProfile: () => {},
  getProfile: () => {},
});

const groups: { [key: string]: ProfileType } = {
  "/MT/GOV/MTI/MTEMPRESARIAL": "company",
  "/MT/GOV/MTI/MTSERVIDOR": "server",
  "/mt-dev/GOV/MTI/MTCIDADAO": "default",
};

export const ProfileProvider: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { profile } = usePortalProvider();
  const [profileType, setProfileType] = useState<ProfileType>("default");
  const [availableProfiles, setAvailableProfiles] = useState<ProfileType[]>([
    "default",
  ]);

  useEffect(() => {
    getProfile();
  });

  useEffect(() => {
    const evaluateAvailableProfiles = () => {
      const userProfileGroups = (profile?.groups as string[]) || [];
      console.log("User Profile Groups:", userProfileGroups);

      let profiles = userProfileGroups
        .filter((userProfileGroup) =>
          Object.keys(groups).includes(userProfileGroup)
        )
        .map((profile) => groups[profile]);
      console.log("profiles: ", profiles);
      if (profiles.length > 0) {
        setAvailableProfiles(profiles);
      }
    };

    evaluateAvailableProfiles();
  }, [profile?.groups]);

  const getProfile = useCallback(() => {
    const savedProfile = localStorage.getItem(ProfileLocalStorage);
    console.log("Saved Profile:", savedProfile);
    return savedProfile;
  }, []);

  const updateProfile = (newProfile: ProfileType) => {
    console.log("new profile: ", newProfile);
    setProfileType(newProfile);
    localStorage.setItem(ProfileLocalStorage, newProfile);
  };

  const profileColors = getProfileColorsFromContext(profileType);

  return (
    <ProfileContext.Provider
      value={{
        profile: profileColors,
        availableProfiles,
        setProfile: updateProfile,
        getProfile,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => useContext(ProfileContext);

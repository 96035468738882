import { FC } from "react";

export const HeaderLogo: FC<React.ImgHTMLAttributes<HTMLImageElement>> = (
  props
) => (
  <div className="xvia-header-logo">
    <a href="/">
      <img {...props} alt="logo" />
    </a>
  </div>
);

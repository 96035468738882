import { FC, useEffect } from "react";
import { useKeycloak } from "@react-keycloak/ssr";
import { useHistory, useLocation } from "react-router-dom";
import { removeUrlParam } from "../util/url";
import { usePortalProvider } from "../providers/portal-provider";

export const LoginContainer: FC = ({ children }) => {
  const { keycloak, initialized } = useKeycloak();
  const { setAccessToken } = usePortalProvider();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (initialized && !!keycloak && keycloak.authenticated) {
      setAccessToken(keycloak.token);
    } else {
      setAccessToken(undefined);
    }

    let url = location.search;
    let hash = location.hash;

    url = removeUrlParam(url, "code");
    url = removeUrlParam(url, "state");
    url = removeUrlParam(url, "session_state");
    url = removeUrlParam(url, "noRedirect");

    history.replace({
      search: url,
      hash: hash,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak, initialized, setAccessToken]);

  return <>{children}</>;
};

export function getSlugFromPath(path: string, basePath?: string): string {
  let base = basePath;
  let slug = path;
  if (base) {
    // Sanitize the base path
    base = base.replace(/^\/+/, "").replace(/\/+$/, "");
    slug = slug.replace(base, "");
  }

  // Sanitize the base pathname
  slug = slug.replace(/^\/+/, "").replace(/\/+$/, "");

  return slug.split("/")[0];
}

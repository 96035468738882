import React from "react";
import { Topbar } from "..";

export const TopbarElement = () => (
  <React.Fragment>
    <Topbar.Mobile />

    <Topbar.Desktop />
  </React.Fragment>
);

import React, { createContext, useState, useEffect, useCallback } from "react";
import axios, { AxiosError } from "axios";
import { usePortalProvider } from "../providers/portal-provider";
import { NotificationContextType, Notification } from "../types/notifications";
import { useKeycloak } from "@react-keycloak/ssr";

interface NotificationsProviderProps {
  children: React.ReactNode;
}

export const NotificationsContext =
  createContext<NotificationContextType | null>(null);

const NotificationsProvider = ({ children }: NotificationsProviderProps) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [notificationsLoaded, setNotificationsLoaded] =
    useState<boolean>(false);
  const { env } = usePortalProvider();
  const { initialized, keycloak } = useKeycloak();
  const accessToken = keycloak?.token;

  const loadNotifications = useCallback(async () => {
    try {
      const headers = accessToken
        ? {
            Authorization: `Bearer ${accessToken}`,
          }
        : undefined;

      const response = await axios.get<Notification[]>(
        `${env?.BACKEND_LOCAL || ""}/v1/notification/notifications`,
        {
          headers,
        }
      );

      // para testes locais da lista de notificações
      // const response = await axios.get<Notification[]>(
      //   `https://dev.portal.mt.gov.br/v1/notification/notifications`,
      //   {
      //     headers,
      //   }
      // );

      const responseNotifications = response || [];

      setNotificationsLoaded(true);
      setNotifications(responseNotifications.data);
    } catch (e) {
      setNotificationsLoaded(true);
      console.log((e as AxiosError).message);
    }
  }, [env, accessToken]);

  const readNotification = useCallback(
    async (messageId: string) => {
      try {
        const headers = accessToken
          ? {
              Authorization: `Bearer ${accessToken}`,
            }
          : undefined;

        const response = await axios.post(
          `${env?.BACKEND_LOCAL || ""}/v1/notification/${messageId}/read`,
          {},
          {
            headers,
          }
        );

        if (response.status) {
          notifications.filter((notification: Notification) => {
            if (notification.messageId === messageId) {
              notification.read = true;
              return setNotifications([...notifications]);
            }
            return true;
          });
        }
      } catch (e) {
        console.log((e as AxiosError).message);
      }
    },
    [env, accessToken, notifications]
  );

  const getUnreadNotifications = (): number => {
    const countUnread = notifications.filter(
      (notification) => notification.read === false
    ).length;

    return countUnread < 99 ? countUnread : 99;
  };

  useEffect(() => {
    if (initialized && keycloak?.authenticated) {
      loadNotifications();
    }
  }, [initialized, keycloak?.authenticated, loadNotifications]);

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        readNotification,
        notificationsLoaded,
        getUnreadNotifications,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;

import React, { FC } from "react";

import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from "@mui/material";
import { useState } from "react";
import Menu from "@mui/material/Menu";
import {
  Menu as MenuIcon,
  AccountCircle as AccountCircleIcon,
} from "@mui/icons-material";
import { useBreakpoint } from "@/hooks/useBreakpoint";

type HeaderElementsAuthorizedMobileProps = {
  name: {} | undefined;
  email: string;
  onHandleRequest: (path: string) => void;
  onLogout: () => void;
};

export const HeaderElementsAuthorizedMobile: FC<HeaderElementsAuthorizedMobileProps> =
  ({ name, email, onLogout, onHandleRequest }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const isMobile = useBreakpoint();

    const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
      setAnchorEl(null);
    };

    if (!isMobile) {
      return null;
    }

    return (
      <React.Fragment>
        <IconButton
          onClick={handleClickMenu}
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <MenuIcon fontSize="medium" />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClickMenu}
          onClick={handleCloseMenu}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          style={{ maxWidth: 264 }}
        >
          <List>
            <ListItem>
              <ListItemText
                style={{ wordWrap: "break-word" }}
                primary={name}
                secondary={email}
              ></ListItemText>
            </ListItem>
          </List>
          <Divider />
          <MenuList>
            <MenuItem onClick={() => onHandleRequest("/app/painel-cidadao")}>
              <ListItemIcon>
                <AccountCircleIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText>Painel Cidadão</ListItemText>
            </MenuItem>

            <Divider />

            <MenuItem onClick={onLogout}>
              <ListItemText>Sair</ListItemText>
            </MenuItem>
          </MenuList>
        </Menu>
      </React.Fragment>
    );
  };

import { Topbar } from "..";

import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import { ReactComponent as Flickr } from "assets/icons/Flickr.svg";

export const TopbarDesktop: React.FC<
  React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>
> = ({ children, ...rest }) => {
  return (
    <Topbar.Root {...rest}>
      <div className="xvia-header-topbar__desktop-links">
        <Topbar.Link link="http://www.transparencia.mt.gov.br">
          Transparência
        </Topbar.Link>

        <Topbar.Link link="https://legislacao.mt.gov.br/mt">
          Legislação
        </Topbar.Link>

        <Topbar.Link link="/app/catalog/orgao">
          Secretarias do Governo
        </Topbar.Link>
      </div>

      <div className="xvia-header-topbar__desktop-icons">
        <Topbar.Link link="https://www.facebook.com/govmatogrosso/">
          <FacebookOutlined />
        </Topbar.Link>

        <Topbar.Link link="https://twitter.com/GovMatoGrosso">
          <TwitterOutlined />
        </Topbar.Link>

        <Topbar.Link link="https://www.instagram.com/govmatogrosso/">
          <InstagramOutlined />
        </Topbar.Link>

        <Topbar.Link link="https://www.youtube.com/@secom_mt">
          <YoutubeOutlined />
        </Topbar.Link>

        <Topbar.Link link="https://www.flickr.com/photos/governodematogrossoimagem">
          <Flickr className="anticon" />
        </Topbar.Link>
      </div>
    </Topbar.Root>
  );
};

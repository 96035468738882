import React, { FC, MouseEventHandler } from "react";
import { Result, Button } from "antd";

export interface NotFoundProps {
  action: MouseEventHandler<HTMLAnchorElement>;
}

export const NotFound: FC<NotFoundProps> = ({ action }) => {
  return (
    <Result
      status="404"
      title="Página não encontrada"
      subTitle="O conteúdo que você procura não foi encontrado em nossos
              servidores."
      extra={
        <Button type="primary" onClick={action}>
          Voltar para a página inicial
        </Button>
      }
    />
  );
};

import { usePortalProvider } from "@/providers/portal-provider";
import { useKeycloak } from "@react-keycloak/ssr";
import { KeycloakInstance } from "keycloak-js";
import { useEffect } from "react";
import { HeaderElementsUnauthorized } from "./HeaderElementsUnauthorized";
import { HeaderElementsAuthorized } from "./HeaderElementsAuthorized";

export const HeaderElements = () => {
  const { keycloak, initialized } = useKeycloak<KeycloakInstance>();
  const { setProfile } = usePortalProvider();

  useEffect(() => {
    if (!initialized) {
      return;
    }

    if (keycloak?.authenticated) {
      keycloak?.loadUserInfo().then((profile) => {
        setProfile(profile);
      });
    }
  }, [keycloak, initialized, setProfile]);

  return (
    <div className="xvia-header-elements">
      <HeaderElementsAuthorized />

      <HeaderElementsUnauthorized />
    </div>
  );
};

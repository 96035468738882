import React, { useEffect } from "react";

import { Route, Switch, useLocation } from "react-router-dom";
import { AppLoaderContainer } from "./containers/AppLoaderContainer";
import { NotFoundContainer } from "./containers/NotFoundContainer";
import { LoginContainer } from "./containers/LoginContainer";
import { AppMessageListener } from "./containers/AppMessageListener";
import ReactGA from "react-ga4";
import { HeaderContainer } from "./containers/HeaderContainer";
import Notifications from "./routes/Notifications";
import { usePageTrackingGA } from "./hooks";
import NotificationsProvider from "./context/NotificationsContext";

const App = () => {
  // GA Tracking
  const location = useLocation();

  usePageTrackingGA(location);

  useEffect(() => {
    const googleAnalyticsId =
      typeof window !== "undefined" ? window?.GOOGLE_ANALYITCS_ID : undefined;
    if (!googleAnalyticsId) return;
    ReactGA.initialize(googleAnalyticsId);
  }, []);

  return (
    <LoginContainer>
      <AppMessageListener />
      <div className="xvia-main-app">
        <NotificationsProvider>
          <HeaderContainer />
          <div className="xvia-content-area">
            <Switch>
              <Route path={"/app"}>
                <AppLoaderContainer />
              </Route>
              <Route exact={true} path="/">
                <AppLoaderContainer slug={"catalog"} />
              </Route>
              <Route exact={true} path="/notifications">
                <Notifications />
              </Route>
              <Route path="*">
                <NotFoundContainer />
              </Route>
            </Switch>
          </div>
        </NotificationsProvider>
      </div>
    </LoginContainer>
  );
};

export default App;

import React, { FC, MouseEventHandler } from "react";
import { Button, Result } from "antd";

export interface NotAuthorizedProps {
  action: MouseEventHandler<HTMLAnchorElement>;
}

export const NotAuthorized: FC<NotAuthorizedProps> = ({ action }) => {
  return (
    <div>
      <Result
        status="403"
        title="401"
        subTitle="Desculpe, você não está autorizado a acessar esta página."
        extra={
          <Button type="primary" onClick={action}>
            Voltar para a página inicial
          </Button>
        }
      />
    </div>
  );
};

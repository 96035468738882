import React, { useEffect } from "react";

import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  YoutubeOutlined,
  CloseOutlined,
  MoreOutlined,
} from "@ant-design/icons";

import { ReactComponent as Flickr } from "assets/icons/Flickr.svg";

import { Topbar } from "..";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Divider } from "antd";
import IconButton from "@mui/material/IconButton";
import { useBreakpoint } from "@/hooks/useBreakpoint";

export const TopbarMobile: React.FC<
  React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>
> = ({ children, ...rest }) => {
  const [drawerOpened, setDrawerOpened] = React.useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setDrawerOpened(open);
    };

  const isMobile = useBreakpoint();

  useEffect(() => {
    if (!isMobile) {
      setDrawerOpened(false);
    }
  }, [isMobile]);

  return (
    <React.Fragment>
      <SwipeableDrawer
        anchor="top"
        open={drawerOpened}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <div className="xvia-header-topbar__drawer-mobile">
          <div className="xvia-header-topbar__drawer-mobile__close">
            <IconButton
              onClick={toggleDrawer(false)}
              id="close-button"
              aria-label="close"
            >
              <CloseOutlined />
            </IconButton>
          </div>

          <ul>
            <li>
              <Topbar.Link link="http://www.transparencia.mt.gov.br">
                Transparência
              </Topbar.Link>
            </li>
            <li>
              <Topbar.Link link="https://legislacao.mt.gov.br/mt">
                Legislação
              </Topbar.Link>
            </li>
            <li>
              <Topbar.Link link="/app/catalog/orgao">
                Secretarias do Governo
              </Topbar.Link>
            </li>
          </ul>

          <Divider />

          <div className="xvia-header-topbar__drawer-mobile__links">
            <Topbar.Link link="https://www.facebook.com/govmatogrosso/">
              <FacebookOutlined />
            </Topbar.Link>

            <Topbar.Link link="https://twitter.com/GovMatoGrosso">
              <TwitterOutlined />
            </Topbar.Link>

            <Topbar.Link link="https://www.instagram.com/govmatogrosso/">
              <InstagramOutlined />
            </Topbar.Link>

            <Topbar.Link link="https://www.youtube.com/@secom_mt">
              <YoutubeOutlined />
            </Topbar.Link>

            <Topbar.Link link="https://www.flickr.com/photos/governodematogrossoimagem">
              <Flickr className="anticon" />
            </Topbar.Link>
          </div>
        </div>
      </SwipeableDrawer>

      <Topbar.Root isMobile {...rest}>
        <MoreOutlined onClick={toggleDrawer(true)} />

        <div className="xvia-header-topbar__mobile container">
          <Topbar.Link link="https://www.facebook.com/govmatogrosso/">
            <FacebookOutlined />
          </Topbar.Link>

          <Topbar.Link link="https://twitter.com/GovMatoGrosso">
            <TwitterOutlined />
          </Topbar.Link>

          <Topbar.Link link="https://www.instagram.com/govmatogrosso/">
            <InstagramOutlined />
          </Topbar.Link>

          <Topbar.Link link="https://www.youtube.com/@secom_mt">
            <YoutubeOutlined />
          </Topbar.Link>

          <Topbar.Link link="https://www.flickr.com/photos/governodematogrossoimagem">
            <Flickr className="anticon" />
          </Topbar.Link>
        </div>
      </Topbar.Root>
    </React.Fragment>
  );
};

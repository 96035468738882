import { useEffect, useState, useContext } from "react";
import { useKeycloak } from "@react-keycloak/ssr";
import { Row, Col, Spin, Card } from "antd";
import { ModalNotification } from "../components/Modal/ModalNotification";
import { ReactComponent as InfoNotificationCircle } from "../assets/icons/InfoNotificationCircle.svg";
import { ReactComponent as CriticalNotification } from "../assets/icons/CriticalNotification.svg";
import { ReactComponent as ErrorNotification } from "../assets/icons/ErrorNotification.svg";
import { ReactComponent as SuccessNotification } from "../assets/icons/SuccessNotification.svg";
import { ReactComponent as SystemNotification } from "../assets/icons/SystemNotification.svg";
import { ReactComponent as WarningNotification } from "../assets/icons/WarningNotification.svg";
import { ReactComponent as RightOutlined } from "../assets/icons/RightOutlined.svg";
import { ReactComponent as Danger } from "../assets/icons/Danger.svg";
import { NotificationsContext } from "../context/NotificationsContext";
import { NotificationContextType, Notification } from "../types/notifications";
import classnames from "classnames";

const setIconNotification = (level: string) => {
  const levelNotification = level.toUpperCase();

  switch (levelNotification) {
    case "WARNING":
      return <WarningNotification className="notification-icon" />;
    case "SYSTEM":
      return <SystemNotification className="notification-icon" />;
    case "SUCCESS":
      return <SuccessNotification className="notification-icon" />;
    case "CRITICAL":
      return <CriticalNotification className="notification-icon" />;
    case "ERROR":
      return <ErrorNotification className="notification-icon" />;
    case "DANGER":
      return <Danger className="notification-icon" />;
    default:
      return <InfoNotificationCircle className="notification-icon" />;
  }
};

const Notifications = () => {
  const [loading, setLoading] = useState(true);
  const { notifications, readNotification, notificationsLoaded } = useContext(
    NotificationsContext
  ) as NotificationContextType;
  const [activeModalNotificationId, setActiveModalNotificationId] =
    useState("");
  const { initialized, keycloak } = useKeycloak();
  const accessToken = keycloak?.token;

  const closeModal = () => {
    setActiveModalNotificationId("");
  };

  const showModal = async (notification: Notification) => {
    if (!notification.read) {
      readNotification(notification.messageId);
    }

    setActiveModalNotificationId(notification.messageId);
  };

  useEffect(() => {
    if (!initialized) {
      setLoading(true);
      return;
    }

    if (initialized && !keycloak?.authenticated) {
      (window as Window).location = "/";
      return;
    }

    setLoading(false);
  }, [keycloak?.authenticated, initialized, accessToken]);

  if (loading || !notificationsLoaded) {
    return <Spin />;
  }

  // console.log("notifications", notifications);

  return (
    <div>
      <h1>Notificações</h1>
      <Card bordered={false} bodyStyle={{ padding: 0 }}>
        {notifications && notifications.length > 0 ? (
          <div>
            {notifications.map((notification: Notification, index) => (
              <div key={`${index}--notif`}>
                <div
                  className={classnames({
                    "notification-row": true,
                    "notification-row__read": !notification.read,
                  })}
                  onClick={() => showModal(notification)}
                >
                  <Row
                    wrap={false}
                    align="middle"
                    className="notification-row__content"
                  >
                    <Col flex="none">
                      {setIconNotification(notification.level)}
                    </Col>
                    <Col flex="auto">
                      <p className="notification-row__content-title">
                        <strong>{notification.title}</strong>
                      </p>
                      <p className="notification-row__content-description">
                        {notification.body.replace(/(.{50})..+/, "$1…")}
                      </p>
                    </Col>
                    <Col className="notification-row__content-btn" flex="end">
                      <RightOutlined />
                    </Col>
                  </Row>
                  {notifications.length - 1 !== index ? (
                    <hr style={{ margin: 0 }} />
                  ) : null}
                </div>
                <ModalNotification
                  showModal={
                    activeModalNotificationId === notification.messageId
                  }
                  closeModal={closeModal}
                  notification={notification}
                />
              </div>
            ))}
          </div>
        ) : (
          <h2 className="notification-empty">Sem notificações.</h2>
        )}
      </Card>
    </div>
  );
};

export default Notifications;

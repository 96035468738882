import React, { FC, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { NotFound, NotFoundProps } from "../components/EmptyState/NotFound";

export const NotFoundContainer: FC = () => {
  const { push } = useHistory();

  const action = useCallback(() => {
    console.log(window, "MSG_FROM_XVIAPORTAL2");
    if (window && window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "CLOSE_PAGE",
          payload: {},
        })
      );
    } else {
      push("/");
    }
  }, [push]);

  const props: NotFoundProps = {
    action,
  };

  return <NotFound {...props} />;
};

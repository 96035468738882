import { useState, useEffect } from "react";

const MOBILE_BREAKPOINT = 768;

export function useBreakpoint(breakpoint: number = MOBILE_BREAKPOINT) {
  const [isBreakpoint, setIsBreakpoint] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsBreakpoint(window.innerWidth < breakpoint);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [breakpoint]);

  return isBreakpoint;
}

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./assets/less/main.less";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { KeycloakProvider } from "./providers/keycloak-provider";
import { PortalContext, PortalProvider } from "./providers/portal-provider";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "./providers/theme-provider";
import { ProfileProvider } from "./providers/profile-provider";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://93f2d1e09cc32db0382c864ff00114a9@sentry.mti.mt.gov.br/68",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

let context: Partial<PortalContext> = {};
if (process.env.NODE_ENV !== "production") {
  context = {
    env: {
      BACKEND_ENDPOINT: BACKEND_ENDPOINT,
      SMARTPASS_LOGIN_URL: SMARTPASS_LOGIN_URL,
      SMARTPASS_LOGIN_REALM: SMARTPASS_LOGIN_REALM,
      SMARTPASS_LOGIN_CLIENT_ID: SMARTPASS_LOGIN_CLIENT_ID,
    },
  };
  window.__XVIA_PORTAL_LOADED__ = true;
}

ReactDOM.hydrate(
  <React.StrictMode>
    <PortalProvider context={context as PortalContext}>
      <KeycloakProvider>
        <BrowserRouter basename={"/"}>
          <ProfileProvider>
            <ThemeProvider>
              <App />
            </ThemeProvider>
          </ProfileProvider>
        </BrowserRouter>
      </KeycloakProvider>
    </PortalProvider>
  </React.StrictMode>,
  document.getElementById("xvia-main")
);

// If you want to start measuring performance in your app, pass a function
// to log results here (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

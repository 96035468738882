import React, { FC, MouseEventHandler } from "react";
import { Button, Result } from "antd";

export interface GenericErrorProps {
  action: MouseEventHandler<HTMLAnchorElement>;
}

export const GenericError: FC<GenericErrorProps> = ({ action }) => {
  return (
    <Result
      status="500"
      title="500"
      subTitle="Desculpe, a aplicação que você está tentando carregar está temporariamente indisponível."
      extra={
        <Button type="primary" onClick={action}>
          Voltar para a página inicial
        </Button>
      }
    />
  );
};

import { FC, useEffect, useState } from "react";
import { AppResponse } from "shared-types";
import { useDomElement } from "../../util/use-dom-element";

export interface AppBundleLaucherProps {
  app: AppResponse;
}

export const AppBundleLaucher: FC<AppBundleLaucherProps> = ({ app }) => {
  const doc = typeof document !== "undefined" ? document : ({} as Document);

  const [isFullyLoaded, setFullyLoaded] = useState(false);

  useDomElement(
    "script",
    app.parsedContent.headerScripts,
    doc.head,
    !isFullyLoaded,
    [app, isFullyLoaded]
  );
  useDomElement(
    "link",
    app.parsedContent.headerLinks,
    doc.head,
    !isFullyLoaded,
    [app, isFullyLoaded]
  );
  useDomElement(
    "style",
    app.parsedContent.headerStyles,
    doc.head,
    !isFullyLoaded,
    [app, isFullyLoaded]
  );

  useDomElement(
    "script",
    app.parsedContent.bodyScripts,
    doc.body,
    !isFullyLoaded,
    [app, isFullyLoaded]
  );
  useDomElement(
    "style",
    app?.parsedContent.bodyStyles,
    doc.body,
    !isFullyLoaded,
    [app, isFullyLoaded]
  );

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.__XVIA_PORTAL_LOADED__) {
        setFullyLoaded(true);
        return;
      }

      const fn = () => {
        setFullyLoaded(true);
      };

      window.addEventListener("__XVIA_PORTAL_LOADED__", fn);

      return () => {
        window.removeEventListener("__XVIA_PORTAL_LOADED__", fn);
      };
    }
  }, [setFullyLoaded]);

  return null;
};

export function getCookie(name: string): string | undefined {
  const cookies = document.cookie.split(";");
  const cookie = cookies.find((c) => c.trim().startsWith(name));
  let cookieValue: string | undefined;

  if (cookie) {
    const [, value] = cookie.split("=");
    cookieValue = value?.trim();
  }

  return cookieValue;
}

export function getDecodedCookie(name: string): string | undefined {
  const encodedURICookie = getCookie(name);

  if (!encodedURICookie) {
    return undefined;
  }

  try {
    const decodedURICookie = decodeURIComponent(encodedURICookie);
    return window.atob(decodedURICookie);
  } catch (error) {
    console.error("Failed to decode cookie:", error);
    return undefined;
  }
}

export function deleteCookie(name: string, path = "/"): void {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path};`;
}

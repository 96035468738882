export enum RequestStatus {
  UNAUTHORIZED = 401,
  NOT_VALIDATED = 400,
  SUCCESS = 200,
  NOT_FOUND = 404,
  INTERNAL_ERROR = 500,
}

export const MAX_RETRIES = 3;
const RETRY_DELAY_MS = 1000;

export interface ServiceInitSettings {
  endpoint: string;
  accessToken?: string;
}

class ServiceError extends Error {
  public reason: any;
  public status: RequestStatus;
  constructor(message: string, status: RequestStatus, reason: any) {
    super(message);
    this.status = status;
    this.reason = reason;
  }
}

export class UnauthorizedError extends ServiceError {}
export class NotFoundError extends Error {}

export class Service {
  private apiEndpoint: string;
  private accessToken?: string;

  constructor(settings: ServiceInitSettings) {
    this.apiEndpoint = settings.endpoint || "";
    this.accessToken = settings.accessToken;
  }

  public request(path: string, request: RequestInit = {}): Promise<any> {
    const url = this.apiEndpoint + path;

    if (this.accessToken) {
      return this.fetchWithToken(url, request, this.accessToken);
    }

    return this.fetchWithRetry(url, request);
  }

  public setAccessToken(accessToken?: string) {
    this.accessToken = accessToken;
  }

  private fetchWithToken = (
    url: string,
    request: RequestInit,
    token: string
  ) => {
    // Add the authentication header
    const req = {
      ...request,
      headers: {
        ...request.headers,
        Authorization: `Bearer ${token}`,
      },
    };

    // Try to issue a new authorized request
    return this.fetchWithRetry(url, req);
  };

  private fetchWithRetry = (
    url: string,
    options: RequestInit,
    n = 1
  ): Promise<any> => {
    return new Promise((resolve, reject) => {
      fetch(url, options)
        .then((response) => {
          if (response.status >= 400 && response.status <= 403) {
            return response.text().then((text) => {
              let reason;
              try {
                reason = JSON.parse(text);
              } catch (ex) {
                reason = text;
              }
              throw new UnauthorizedError(
                "Unauthorized error",
                reason,
                response.status
              );
            });
          }

          if (response.status === RequestStatus.NOT_FOUND) {
            throw new NotFoundError();
          }

          if (response.status !== RequestStatus.SUCCESS) {
            throw new Error(response.status.toString());
          }

          response.json().then(resolve);
        })
        .catch((error) => {
          // We don't want to retry these errors
          if (
            error instanceof UnauthorizedError ||
            error instanceof NotFoundError
          )
            return reject(error);

          if (n === MAX_RETRIES) return reject(error);

          setTimeout(() => {
            this.fetchWithRetry(url, options, n + 1)
              .then(resolve)
              .catch(reject);
          }, RETRY_DELAY_MS);
        });
    });
  };
}
